<style scoped lang="less">
  @import url('/static/document/img-print.css');
  .img-doc-print {
    display: flex;
    height: 100%;
    justify-content: center;
    background-color: rgba(245, 247, 249, 1);
  }
  .left {
    width: 200px;
  }
  .right {
    display: flex;
    flex-direction: column;
  }
  .doc-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    overflow: auto;
  }
  .doc-content {
    &:empty::after {
      content: '';
      display: block;
      width: 21.0529166667cm;
    }
    flex: 1;
    padding: 1cm;
    box-sizing: border-box;
  }
  .choose-list {
    display: flex;
    flex-wrap: wrap;
  }
  .choose-img {
    width: 230px;
    height: 170px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    position: relative;
    img {
      user-select: none;
      transition: all .3s;
      border-radius: 5px;
      border: 1px solid #EEE;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    &:hover {
      img {
        border-color: rgb(8, 143, 206);
      }
    }
  }
  .img-doc-print {
    .doc-content {
      .page {
        padding: 2cm 1.2cm;
        transition: all .3s;
        cursor: pointer;
        border: 1px solid rgba(202, 204, 210, 1);
        &.active {
          border-color: rgb(8, 143, 206);
        }
        & + .page {
          margin-top: 25px;
        }
      }
      .imgs {
        .img {
          cursor: pointer;
          img {
            user-select: none;
            transition: all .3s;
          }
          &:after {
            transition: all .3s;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            pointer-events: none;
            border: 1px solid transparent;
          }
          &.active {
            &:after {
              border-color: rgb(8, 143, 206);
            }
          }
        }
      }
    }
  }
  .plane {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 5px;
    .block {
      margin-right: 10px;
      box-sizing: border-box;
      background-color: #FFF;
      border: 1px solid transparent;
      border-radius: 8px;
      margin-top: 5px;
      padding: 5px;
      transition: all .3s;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          display: flex;
          align-items: center;
          i {
            margin-right: 10px;
            margin-left: 10px;
          }
        }
        .action-icon {
          cursor: pointer; color: red; font-size: 16px; font-weight: 800;
        }
      }
      .content {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #EEE;
      }
      &.tap {
        cursor: pointer;
        &:hover {
          background-color: rgba(8, 143, 206, .05);
          border-color: rgba(8, 143, 206, .1);
        }
      }
    }
  }
</style>

<style lang="less" scoped>
.img-doc-print {
  .map {
    box-shadow: 0 0 10px 5px rgb(219, 219, 219);
    border-right: 1px solid #EEE;
    background-color: rgba(245, 247, 249, 1);
    height: 100%;
    position: relative;
    overflow: auto;
    box-sizing: border-box;
    .page {
      width: 100%;
      cursor: pointer;
      &.active {
        border-color: rgb(8, 143, 206);
      }
      border: 1px solid #caccd2;
      .img {
        img {
          user-select: none;
          transition: all .3s;
        }
        &:after {
          transition: all .3s;
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          left: 0;
          bottom: 0;
          pointer-events: none;
          border: 1px solid transparent;
        }
        &.active {
          &:after {
            border-color: rgb(8, 143, 206);
          }
        }
      }
      & + .page {
        margin-top: 10px;
      }
    }
    .viewport {
      display: none;
      pointer-events: none;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.25);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}
</style>

<style lang="less">
  .img-doc-print {
    .block-icon-actions {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 2px;
      }
      .btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .3s;
        padding-left: 10px;
        .img-action-desc {
          margin-left: 5px;
          white-space: nowrap;
          padding: 0px;
          transition: all .3s;
        }
        .img-action-icon {
          font-size: 20px;
          transition: all .3s;
        }
        &:hover {
          transform: scale(1.15) translateX(10px);
          .img-action-desc, .img-action-icon {
            color: rgb(8, 143, 206);
          }
        }
      }
      .split {
        background: rgb(233,233,233);
        height: 1px;
      }
    }
  }
</style>

<template>
  <div style="height: 100%;position: relative;">
    <div class="img-doc-print">
      <div class="left scrollbar">
        <div class="map" ref="map" :style="{
          paddingTop: previewMap.map.paddingTop + 'px',
          paddingBottom: previewMap.map.paddingBottom + 'px',
          paddingLeft: previewMap.map.paddingLeft + 'px',
          paddingRight: previewMap.map.paddingRight + 'px'
        }">
          <div ref="map-placeholder" style="pointer-events: none;width: 100%;"></div>
          <div ref="viewport" class="viewport" :style="{
            top: previewMap.view.scrollTop + 'px',
            height: previewMap.view.height + 'px',
            paddingTop: previewMap.view.paddingTop + 'px',
            paddingBottom: previewMap.view.paddingBottom + 'px'
          }"></div>
          <div v-for="(page, i) in pages" :key="i" class="page" :class="{
            [page.direction]: true,
            active: cursor.page === i,
          }" :data-page-index="i" :style="{
            height: previewMap.page.height + 'px',
            paddingTop: previewMap.page.paddingTop + 'px',
            paddingBottom: previewMap.page.paddingBottom + 'px',
            paddingLeft: previewMap.page.paddingLeft + 'px',
            paddingRight: previewMap.page.paddingRight + 'px'
          }" @click="jumpToPage(i)">
            <div class="imgs" :style="{
              justifyContent: page.justifyContent
            }">
              <div :data-page-img-index="i + '-' + l" v-for="(img, l) in page.imgs" :key="l + '-' + img.src" :class="{
                  active: cursor.page === i && cursor.index === l
                }" class="img" :style="{
                  [page.direction === 'row' ? 'max-width' : 'max-height']: getUnit2Style(getMapUnit2Px(page.direction === 'row' ? img.width : img.height, page.direction === 'row' ? 'width' : 'height')),
                  [page.direction === 'row' ? 'align-items' : 'justify-content']: img.align,
                }">
                <!-- <img :src="img.src" @click.stop="jumpToImg(i, l)" /> -->
                <img :src="img.src" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="doc-content-wrap scrollbar" ref="wrap">
        <div class="doc-content" ref="content">
          <div v-for="(page, i) in pages" :key="i" class="page" :class="{
            [page.direction]: true,
            active: cursor.page === i
          }" :data-page-index="i" @click.stop="togglePage(i)">
            <div class="imgs" :style="{
              justifyContent: page.justifyContent
            }">
              <div :data-page-img-index="i + '-' + l" v-for="(img, l) in page.imgs" :key="l + '-' + img.src" :class="{
                  active: cursor.page === i && cursor.index === l
                }" class="img" :style="{
                  [page.direction === 'row' ? 'max-width' : 'max-height']: getUnit2Style(page.direction === 'row' ? img.width : img.height),
                  [page.direction === 'row' ? 'align-items' : 'justify-content']: img.align,
                }">
                <img :src="img.src" @click.stop="toggleImg(i, l)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="plane">
          <div class="block">
            <div class="title">
              <div class="name">
                <i class="iconfont icon-pages"></i>
                <span style="font-size: 18px; font-weight: 800;">图片打印预览编辑器</span>
              </div>
              <i @click="quit" style="margin-left: 10px;" class="iconfont icon-qidong action-icon"></i>
            </div>
          </div>
          <div style="flex: 1;overflow: auto;">
            <div class="block" v-show="status.page">
              <div class="title">
                <div class="name">
                  <i class="iconfont icon-break-page"></i>
                  <span style="font-size: 16px;">页面设置: 第{{cursor.page + 1}}页</span>
                </div>
                <i @click="status.page = false" class="iconfont icon-close action-icon" style="font-size: 12px;font-weight: 400;color: #657180;"></i>
              </div>
              <div class="content">
                <div class="block-icon-actions">
                  <template v-for="menu in pageMenus">
                    <div class="split" :key="menu.key + 'split'"  v-if="menu.split"></div>
                    <div :key="menu.key + 'btn'" @click="pageAction(menu)" class="btn">
                      <i class="img-action-icon" :class="{[menu.icon]: true}"></i>
                      <div class="img-action-desc">{{menu.desc}}</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="block" v-show="status.img">
              <div class="title">
                <div class="name">
                  <i class="iconfont icon-img-add"></i>
                  <span style="font-size: 16px;">图片设置: 第{{imgPos + 1}}张</span>
                </div>
                <i @click="status.img = false" class="iconfont icon-close action-icon" style="font-size: 12px;font-weight: 400;color: #657180;"></i>
              </div>
              <div class="content">
                <div class="block-icon-actions" v-loadingx="loading.imgAction">
                  <template v-for="menu in imgMenus">
                    <div class="split" :key="menu.key + 'split'"  v-if="menu.split"></div>
                    <div :key="menu.key + 'btn'" @click="imgAction(menu)" class="btn">
                      <i class="img-action-icon" :class="{[menu.icon]: true}"></i>
                      <div class="img-action-desc">{{menu.desc}}</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="block tap" @click="addPage">
            <div class="title">
              <div class="name">
                <i class="iconfont icon-add"></i>
                <span style="font-size: 16px;">添加一页</span>
              </div>
            </div>
          </div>
          <div class="block tap" @click="print">
            <div class="title">
              <div class="name">
                <i class="iconfont icon-printer"></i>
                <span style="font-size: 16px;">打印</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <fm-modal v-model="status.choose" width="1000px">
      <div class="choose-list">
        <div v-for="(item, i) in baseSources" :key="i + '-' + item.src" class="choose-img">
          <img @click="chooseImg(item)" :src="item.src" />
        </div>
      </div>
    </fm-modal>
    <div ref="cm2px" style="display: none;padding: 2cm 1.2cm;width: 21.0529166667cm;height: 29.7529166667cm;"></div>
    <iframe style="display: none;" ref="iframe"></iframe>
  </div>
</template>

<script>
import { urlImgRotate2Canvas } from './lib'

export default {
  props: {
    sources: { type: Array, default: () => [] }
  },
  data () {
    return {
      baseSources: [],
      pages: [],
      imgs: [],
      cursor: {
        page: null,
        index: null
      },
      insert: {
        page: null,
        index: null,
        pos: null
      },
      pageSize: {},
      status: {
        choose: false,
        page: false,
        img: false
      },
      loading: {
        imgAction: false
      },
      previewMap: {
        map: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0
        },
        page: {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0
        },
        view: {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          scrollTop: 0
        }
      }
    }
  },
  computed: {
    pageMenus () {
      if (this.cursor.page === null || !this.pages.length) {
        return []
      }

      const direction = this.pages[this.cursor.page].direction

      return [
        { label: false, desc: '添加图片', icon: 'iconfont icon-img-add', key: 'add-img' },
        { label: false, split: true, desc: '水平排列', icon: 'iconfont icon-horizontal', key: 'row-direction' },
        { label: false, desc: '垂直排列', icon: 'iconfont icon-vertical', key: 'column-direction' },
        ...(direction === 'column' ? [
          { label: false, split: true, desc: '顶部对齐', icon: 'iconfont icon-flex-start', key: 'page-column-justify-content-flex-start' },
          { label: false, desc: '底部对齐', icon: 'iconfont icon-flex-end', key: 'page-column-justify-content-flex-end' },
          { label: false, desc: '居中对齐', icon: 'iconfont icon-column-justify-center', key: 'page-column-justify-content-center' },
          { label: false, desc: '上下对齐', icon: 'iconfont icon-column-justify-space-evenly', key: 'page-column-justify-content-space-between' },
          { label: false, desc: '等距间隔对齐', icon: 'iconfont icon-column-justify-space-evenly', key: 'page-column-justify-content-space-evenly' }
        ] : [
          { label: false, split: true, desc: '顶部对齐', icon: 'iconfont icon-flex-start', key: 'page-row-justify-content-flex-start' },
          { label: false, desc: '底部对齐', icon: 'iconfont icon-flex-end', key: 'page-row-justify-content-flex-end' },
          { label: false, desc: '居中对齐', icon: 'iconfont icon-row-justify-center', key: 'page-row-justify-content-center' },
          { label: false, desc: '左右对齐', icon: 'iconfont icon-row-justify-space-between', key: 'page-row-justify-content-space-between' },
          { label: false, desc: '等距间隔对齐', icon: 'iconfont icon-row-justify-space-between', key: 'page-row-justify-content-space-evenly' } 
        ]),
        { label: false, split: true, desc: '在此之前插入一页', icon: 'iconfont icon-before-insert', key: 'before-insert' },
        { label: false, desc: '在此之后插入一页', icon: 'iconfont icon-after-insert', key: 'after-insert' },
        { label: false, split: true, desc: '重新自动排版全部图片', icon: 'iconfont icon-layout', key: 'layout' },
        { label: false, split: true, desc: '删除该页', icon: 'iconfont icon-shanchu', key: 'del' }
      ]
    },
    imgMenus () {
      if (this.cursor.page === null || !this.pages.length) {
        return []
      }

      const direction = this.pages[this.cursor.page].direction
      const menus = [
        { label: false, desc: '向左旋转', icon: 'iconfont icon-left-rotate', key: 'left-rotate' },
        { label: false, desc: '向右旋转', icon: 'iconfont icon-right-rotate', key: 'right-rotate' }
      ]
      
      menus.push(...(direction === 'column' ? [
        { label: false, split: true, desc: '左对齐', icon: 'iconfont icon-left-align', key: 'flex-start-align' },
        { label: false, desc: '水平居中', icon: 'iconfont icon-center-align', key: 'center-align' },
        { label: false, desc: '右对齐', icon: 'iconfont icon-right-align', key: 'flex-end-align' },
        { label: false, split: true, desc: '向上移动', icon: 'iconfont icon-go-top', key: 'left-position' },
        { label: false, desc: '向下移动', icon: 'iconfont icon-go-down', key: 'right-position' }
      ] : [
        { label: false, split: true, desc: '顶对齐', icon: 'iconfont icon-flex-start', key: 'flex-start-justify' },
        { label: false, desc: '垂直居中', icon: 'iconfont icon-center-justify', key: 'center-justify' },
        { label: false, desc: '底对齐', icon: 'iconfont icon-flex-end', key: 'flex-end-justify' },
        { label: false, split: true, desc: '向左移动', icon: 'iconfont icon-go-left', key: 'left-position' },
        { label: false, desc: '向右移动', icon: 'iconfont icon-go-right', key: 'right-position' }
      ]))
        
      menus.push(
        { label: false, split: true, desc: '在此之前插入一张图片', icon: 'iconfont icon-before-insert', key: 'before-insert' },
        { label: false, desc: '在此之后插入一张图片', icon: 'iconfont icon-after-insert', key: 'after-insert' },
        { label: false, split: true, desc: '恢复原图', icon: 'iconfont icon-reset', key: 'reset' },
        { label: false, desc: '删除该图', icon: 'iconfont icon-shanchu', key: 'del' }
      )

      return menus
    },
    imgPos () {
      const page = this.cursor.page
      const index = this.cursor.index
      const pages = this.pages
      let pos = 0

      for (let i = 0; pages.length && i <= page; i++) {
        if (i === page) {
          pos += index
        } else {
          pos += pages[i].imgs.length
        }
      }
      return pos
    }
  },
  watch: {
    sources: {
      deep: true,
      handler () {
        this.loadImgData()
      }
    }
  },
  methods: {
    onContentScroll () {
      this.previewMap.view.scrollTop = this.$refs.map.scrollHeight * (this.$refs.wrap.scrollTop / this.$refs.wrap.scrollHeight)
      this.$refs.map.scrollTo(0, this.previewMap.view.scrollTop - this.$refs.viewport.offsetHeight)
    },
    loadPreviewMap () {
      let { width } = getComputedStyle(this.$refs['map-placeholder'])

      width = Number(width.replace('px', ''))

      const viewportAspectRatio = this.$refs.content.offsetWidth / this.$el.offsetHeight
      this.previewMap.view.height = width / viewportAspectRatio

      let { paddingTop, paddingBottom, paddingLeft, paddingRight } = getComputedStyle(this.$refs.content)

      paddingTop = Number(paddingTop.replace('px', ''))
      paddingBottom = Number(paddingBottom.replace('px', ''))
      paddingLeft = Number(paddingLeft.replace('px', ''))
      paddingRight = Number(paddingRight.replace('px', ''))

      this.previewMap.map.paddingTop = this.previewMap.view.height * (paddingTop / this.$el.offsetHeight)
      this.previewMap.map.paddingBottom = this.previewMap.view.height * (paddingBottom / this.$el.offsetHeight)
      this.previewMap.map.paddingLeft = width * (paddingLeft / this.$refs.content.offsetWidth)
      this.previewMap.map.paddingRight = width * (paddingRight / this.$refs.content.offsetWidth)
      
      this.previewMap.page.paddingTop = this.previewMap.view.height * (this.pageSize.paddingTop / this.pageSize.height)
      this.previewMap.page.paddingBottom = this.previewMap.view.height * (this.pageSize.paddingBottom / this.pageSize.height)
      this.previewMap.page.paddingLeft = width * (this.pageSize.paddingLeft / this.pageSize.width)
      this.previewMap.page.paddingRight = width * (this.pageSize.paddingRight / this.pageSize.width)

      const pageAspectRatio = this.pageSize.width / this.pageSize.height
      this.previewMap.page.height = (width - this.previewMap.page.paddingLeft - this.previewMap.page.paddingRight) / pageAspectRatio
    },
    print () {
      let html = this.$refs.content.innerHTML
      // eslint-disable-next-line no-useless-escape
      let tpl = '<!DOCTYPE html><head><link rel="stylesheet" href="/static/document/img-print.css"></head><body class="img-doc-print">' + html + "</body><script>document.addEventListener('DOMContentLoaded', function () {setTimeout(function () {window.print();}, 0);});<\/script></html>"
      this.$refs.iframe.srcdoc = tpl
    },
    delPage () {
      this.pages.splice(this.cursor.page, 1)
      this.imgs = this.pages.map(v => v.imgs).flat()

      if (this.pages.length) {
        if (this.cursor.page >= (this.pages.length - 1)) {
          this.cursor.page = this.pages.length - 1
          this.cursor.index = this.pages[this.cursor.page].imgs.length ? 0 : null
        }
      } else {
        this.cursor.page = null
        this.cursor.index = null
      }

      this.status.page = this.cursor.page !== null
      this.status.img = this.cursor.index !== null
      
      this.$refs.viewport.style.opacity = 0
      this.previewMap.view.scrollTop = 0
      this.$nextTick(this.onContentScroll)
      this.$refs.viewport.style.opacity = 1
    },
    async pageAction (menu) {
      let page = this.pages[this.cursor.page]
      switch (menu.key) {
        case 'add-img':
          this.addImg(this.cursor.page)
          break
        case 'row-direction':
        case 'column-direction':
          page.direction = menu.key.replace('-direction', '')
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'before-insert':
          if (this.cursor.page < 1) {
            this.pages.unshift({
              direction: 'column',
              imgs: []
            })
          } else {
            this.pages.splice(this.cursor.page, 1, {
              direction: 'column',
              imgs: []
            }, this.pages[this.cursor.page])
          }
          this.cursor.page = this.cursor.page + 1
          break
        case 'after-insert':
          if (this.cursor.page >= this.pages.length - 1) {
            this.pages.push({
              direction: 'column',
              imgs: []
            })
          } else {
            this.pages.splice(this.cursor.page, 1, this.pages[this.cursor.page], {
              direction: 'column',
              imgs: []
            })
          }
          break
        case 'page-column-justify-content-flex-start':
        case 'page-column-justify-content-flex-end':
        case 'page-column-justify-content-center':
        case 'page-column-justify-content-space-between':
        case 'page-column-justify-content-space-evenly':
          page.justifyContent = menu.key.replace('page-column-justify-content-', '')
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'page-row-justify-content-flex-start':
        case 'page-row-justify-content-flex-end':
        case 'page-row-justify-content-center':
        case 'page-row-justify-content-space-between':
        case 'page-row-justify-content-space-evenly':
          page.justifyContent = menu.key.replace('page-row-justify-content-', '')
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'layout':
          this.autoLayout()
          break
        case 'del':
          this.delPage()
          break
      }
    },
    async imgAction (menu) {
      this.loading.imgAction = true
      let page = this.pages[this.cursor.page], canvas = null
      let { original, src } = page.imgs[this.cursor.index]

      switch (menu.key) {
        case 'left-rotate':
        case 'right-rotate':
          page.imgs[this.cursor.index].rotate = (page.imgs[this.cursor.index].rotate + ({
            'left-rotate': -90,
            'right-rotate': 90
          })[menu.key]) % 360
          if (page.imgs[this.cursor.index].rotate === 0) {
            page.imgs[this.cursor.index].src = original.src
          } else {
            canvas = await urlImgRotate2Canvas(original.src, page.imgs[this.cursor.index].rotate)
            page.imgs[this.cursor.index].src = await new Promise(resolve => {
              canvas.toBlob((blob) => {
                resolve(URL.createObjectURL(blob))
              }, 'image/png')
            })
          }
          page.imgs[this.cursor.index].width = page.imgs[this.cursor.index].height = undefined
          if (src !== original.src && src.indexOf('blob://') === 0) {
            URL.revokeObjectURL(src)
          }
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'flex-start-justify':
        case 'center-justify':
        case 'flex-end-justify':
          page.imgs[this.cursor.index].align = menu.key.replace('-justify', '')
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'flex-start-align':
        case 'center-align':
        case 'flex-end-align':
          page.imgs[this.cursor.index].align = menu.key.replace('-align', '')
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'before-insert':
          this.addImg(this.cursor.page, this.cursor.index, 'before-insert')
          break
        case 'after-insert':
          this.addImg(this.cursor.page, this.cursor.index, 'after-insert')
          break
        case 'left-position':
          if (this.cursor.index === 0) {
            if (this.cursor.page > 0) {
              this.cursor.page = this.cursor.page - 1
              this.pages[this.cursor.page].imgs.push(page.imgs[this.cursor.index])
              page.imgs.splice(this.cursor.index, 1)
              this.pages.splice(this.cursor.page, 2, this.pages[this.cursor.page], page)
              this.cursor.index = this.pages[this.cursor.page].imgs.length - 1
            }
          } else {
            page.imgs.splice(this.cursor.index - 1, 2, page.imgs[this.cursor.index], page.imgs[this.cursor.index - 1])
            this.pages.splice(this.cursor.page, 1, page)
            this.cursor.index -= 1
          }
          this.focusInCursor();
          break
        case 'right-position':
          if (this.cursor.index >= page.imgs.length - 1) {
            if (this.cursor.page < this.pages.length - 1) {
              this.pages[this.cursor.page + 1].imgs.unshift(page.imgs[this.cursor.index])
              page.imgs.splice(this.cursor.index, 1)
              this.pages.splice(this.cursor.page, 2, page, this.pages[this.cursor.page + 1])
              this.cursor.page += 1
              this.cursor.index = 0
            }
          } else {
            page.imgs.splice(this.cursor.index, 2, page.imgs[this.cursor.index + 1], page.imgs[this.cursor.index])
            this.cursor.index += 1
            this.pages.splice(this.cursor.page, 1, page)
          }
          this.focusInCursor();
          break
        case 'reset':
          if (src !== original.src && src.indexOf('blob://') === 0) {
            URL.revokeObjectURL(src)
          }
          page.imgs[this.cursor.index].src = original.src
          page.imgs[this.cursor.index].rotate = original.rotate
          page.imgs[this.cursor.index].width = original.width
          page.imgs[this.cursor.index].height = original.height
          page.imgs[this.cursor.index].align = original.align
          page.imgs[this.cursor.index].justify = original.justify
          this.pages.splice(this.cursor.page, 1, page)
          break
        case 'del':
          page.imgs.splice(this.cursor.index, 1)
          if (page.imgs.length) {
            if (this.cursor.index >= page.imgs.length - 1) {
              this.cursor.index = page.imgs.length - 1
            }
          } else {
            this.status.img = false
            this.cursor.index = null
          }
          this.pages.splice(this.cursor.page, 1, page)
          break
      }
      this.imgs = this.pages.map(v => v.imgs).flat()
      this.loading.imgAction = false
    },
    focusInCursor () {
      this.$nextTick(() => {
        let el = null
        if (!(!this.cursor.index && this.cursor.index !== 0)) {
          el = this.$refs.content.querySelector('[data-page-img-index="' + this.cursor.page + '-' + this.cursor.index + '"]')
        } else {
          el = this.$refs.content.querySelector('[data-page-index="'+ this.cursor.page + '"]')
        }

        el && el.scrollIntoView()
      })
    },
    jumpToPage (i) {
      this.$refs.content.querySelector('[data-page-index="' + i + '"]').scrollIntoView()
      this.togglePage(i)
    },
    jumpToImg (i, l) {
      this.$refs.content.querySelector('[data-page-img-index="' + i + '-' + l + '"]').scrollIntoView()
      this.toggleImg(i, l)
    },
    togglePage (i) {
      if (this.cursor.page !== i) {
        this.status.img = false
        this.cursor.index = null
      }
      this.cursor.page = i
      this.status.page = true
    },
    toggleImg (i, l) {
      this.cursor.page = i
      this.cursor.index = l
      this.status.img = true
      this.status.page = true
    },
    chooseImg (item) {
      if (this.insert.page !== null && this.pages[this.insert.page]) {
        const img = JSON.parse(JSON.stringify(item))
        const page = this.pages[this.insert.page]

        if (this.insert.pos === 'append') {
          page.imgs.push(img)
        } else if (this.insert.pos === 'before-insert' && page.imgs[this.insert.index]) {
          if (this.insert.index > 0) {
            page.imgs.splice(this.insert.index, 1, img, page.imgs[this.insert.index])
          } else {
            page.imgs.unshift(img)
          }
          this.insert.index = this.insert.index + 1
          this.cursor.index = this.cursor.index + 1
        } else if (this.insert.pos === 'after-insert' && page.imgs[this.insert.index]) {
          if (this.insert.index < page.imgs.length - 1) {
            page.imgs.splice(this.insert.index, 1, page.imgs[this.insert.index], img)
          } else {
            page.imgs.push(img)
          }
        }

        this.pages.splice(this.insert.page, 1, page)
        this.imgs = this.pages.map(v => v.imgs).flat()
      }
      this.status.choose = false
    },
    addImg (page, index = false, pos = 'append') {
      this.insert.page = page
      this.insert.index = index
      this.insert.pos = pos
      this.status.choose = true
    },
    addPage () {
      this.pages.push({
        direction: 'column',
        imgs: []
      })
    },
    getUnit2Style (val) {
      if (!val) {
        return ''
      } else if (typeof val === 'number' || !isNaN(val)) {
        return val + 'px'
      } else {
        return val
      }
    },
    getMapUnit2Px (val) {
      return this.getUnit2Px(val) * this.previewMap.page.height / this.pageSize.height
    },
    getUnit2Px (val) {
      if (!val) {
        return 0
      } else if (typeof val === 'string') {
        if (val.indexOf('%') > -1) {
          return Number(val.replace('%', '')) / 100 * this.pageSize.width
        } else if (val.indexOf('cm') > -1) {
          return this.pageSize.width / 21.0529166667 * Number(val.replace('cm', ''))
        } else if (val.indexOf('px') > -1) {
          return Number(val.replace('px', ''))
        } else {
          return Number(val)
        }
      } else {
        return val
      }
    },
    autoLayout () {
      let pages = []
      this.imgs.forEach((item, index) => {
        let page = {
          direction: item.page !== false ? (this.sources[item.page].direction || 'column') : 'column',
          justifyContent: item.page !== false ? (this.sources[item.page].justifyContent || undefined) : undefined,
          imgs: []
        }

        const used = item.page !== false ? 0 : pages[pages.length - 1].imgs.reduce((a, b) => {
          if (Math.abs(b.rotate) === 90 || Math.abs(b.rotate) === 270) {
            return a + this.getUnit2Px(b.width)
          } else {
            return a + this.getUnit2Px(b.height)
          }
        }, 0)
        console.log('use and height', '使用', used, '剩余', this.pageSize.contentHeight - used, '图高', item.height, '(', this.getUnit2Px(item.height), 'px)', item)
        
        if (!pages.length || (index > 0 && this.imgs[index - 1].page !== item.page) || (item.page === false && this.getUnit2Px(item.height) > (this.pageSize.contentHeight - used))) {
          pages.push(page)
        } else {
          page = pages[pages.length - 1]
        }

        page.imgs.push(item)
      })
      this.cursor.page = null
      this.cursor.index = null
      this.pages = pages
      console.log(pages)
      this.$nextTick(this.loadPreviewMap)
    },
    async loadImgData () {
      let promise = this.sources.map((item, page) => {
        let sources = typeof item === 'object' ? item.sources : [item]
        let sourcePage = typeof item === 'object' ? page : false
        return sources.map(src => {
          return new Promise(resolve => {
            let img = new Image()
            img.src = typeof src === 'object' ? src.src : src
            img.onload = async () => {
              let width = img.width
              let height = img.height

              const ratio = width / height
              if (ratio > 1) {
                height = this.pageSize.contentWidth / ratio
                width = this.pageSize.contentWidth
              } else {
                height = this.pageSize.contentHeight
                width = this.pageSize.contentHeight * ratio
              }

              if (typeof src === 'object') {
                const obj = {
                  src: img.src,
                  width: src.width || width,
                  height: src.height || height,
                  page: sourcePage,
                  rotate: src.rotate || 0,
                  align: src.align || 'center',
                  justify: src.justify || 'center',
                  original: {
                    src: img.src,
                    rotate: src.rotate || 0,
                    sourceWidth: img.width,
                    sourceHeight: img.height,
                    width: src.width || width,
                    height: src.height || height,
                    align: src.align || 'center',
                    justify: src.justify || 'center'
                  }
                }
                if (src.rotate) {
                  let canvas = await urlImgRotate2Canvas(img.src, src.rotate)
                  obj.src = await new Promise(r => {
                    canvas.toBlob((blob) => {
                      r(URL.createObjectURL(blob))
                    }, 'image/png')
                  })
                  obj.width = obj.height = undefined
                }
                return resolve(obj)
              } else {
                return resolve({
                  src: img.src,
                  width: width,
                  height: height,
                  page: sourcePage,
                  rotate: 0,
                  align: 'center',
                  justify: 'center',
                  original: {
                    rotate: 0,
                    src: img.src,
                    width: width,
                    height: height,
                    align: 'center',
                    justify: 'center',
                    sourceWidth: img.width,
                    sourceHeight: img.height
                  }
                })
              }
            }
          })
        })
      }).flat()
      this.baseSources = await Promise.all(promise)
      this.imgs = JSON.parse(JSON.stringify(this.baseSources))
      this.autoLayout()
    },
    quit () {
      this.$emit('quit')
    }
  },
  mounted () {
    const styles = getComputedStyle(this.$refs.cm2px)
    const pageSize = {
      width: Number(styles.width.replace('px', '')),
      height: Number(styles.height.replace('px', '')),
      paddingTop: Number(styles.paddingTop.replace('px', '')),
      paddingLeft: Number(styles.paddingLeft.replace('px', '')),
      paddingRight: Number(styles.paddingRight.replace('px', '')),
      paddingBottom: Number(styles.paddingBottom.replace('px', ''))
    }

    pageSize.contentWidth = pageSize.width - pageSize.paddingLeft - pageSize.paddingRight
    pageSize.contentHeight = pageSize.height - pageSize.paddingTop - pageSize.paddingBottom

    console.log(pageSize)

    this.pageSize = pageSize
    this.loadImgData()

    this.$refs.wrap.addEventListener('scroll', this.onContentScroll)
  }
}
</script>
