<template>
  <div class="sign-page">
    <div class="sign-page-div" :class="{'sign-page-div-no-padding': noPadding}">
      <the-breadcrumb class="sign-page-top-1" v-if="breadcrumb"></the-breadcrumb>
      <fm-search-group class="sign-page-top-2" :search-parms="searchParms" @clickSearch="clickSearch" v-if="searchParms"></fm-search-group>
      <div class="sign-page-content content-block">
        <div class="content-top">
          <fm-title :title-text="titleText" :note-text="noteText" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
            <slot name="title"></slot>
          </fm-title>
        </div>
        <slot name="center"></slot>
        <div class="content-body">
          <fm-table-new
            :simple-filter="simpleFilter"
            :columns="columns"
            :auto-height="true"
            :defaultOptionsFilterMethod="defaultOptionsFilterMethod"
            empty-search="__null"
            v-loadingx="loading"
            border="row"
            ref="table"
            :handler-counter="countFun"
            @counterChange="countDataChange"
            :data-list="dataList"
            :selection="showCheckbox"
            :toolbox="toolbox"
            :export-file-name="exportFileName"
            :stripe="false"
            size="large"
            emptyPlaceholder="-"
            @on-select-change="checkDataChange">
            <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
            <div slot="empty" style="margin: 30px 0;">
              <img src="/static/images/null.png" style="width: 40px;"/>
              <div>暂无数据</div>
            </div>
          </fm-table-new>
        </div>
      </div>
    </div>
    <fm-form-dialog
      :form-title="titleText"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="hisData"
      :mask-closable="false"
      :on-change="onFormChange"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="formHandleClose">
    </fm-form-dialog>
    <fm-modal :title="dataFileTitle" :mask-closable="false" :funs="dataFilefuns" :value="openDialogDataFile" @cancel="dataFileHandleClose" width="600px">
      <file-manage
        v-if="openDialogDataFile"
        :upload-check="dataFileUploadCheck"
        :file-data-ids="fileDataIds"
        @addFile="addFile"
        :funs="dataFilefuns"
        @delFile="delFile"></file-manage>
    </fm-modal>
    <!-- <file-modal
      :open-dialog.sync="openDialogDataFile"
      :dir-path="dataFileDirPath"
      :upload-check="dataFileUploadCheck"
      :file-title="dataFileTitle"
      :funs="dataFilefuns"
      @fileUpdate="dataFileUpdate"
      :mask-closable="false"
      @handleClose="dataFileHandleClose">
    </file-modal> -->
    <data-apply-log v-model="showDataApplyLog" :type="applicableType" :show-tool-box="toolbox" :data-columns="applyDataLogColumns" />
  </div>
</template>

<script>
import TheBreadcrumb from './TheBreadcrumb'
import TableActions from './TableActions'
// import FileModal from './FileModal'
import DataApplyLog from './data-apply/modal.vue'
import FileManage from './FileManage'

export default {
  name: 'BaseSignPage',
  components: {
    TheBreadcrumb, TableActions, DataApplyLog,FileManage
  },
  props: {
    fileParm: {
      type: Object
    },
    dataFileUploadCheck: {
      type: Function
    },
    onFormChange: {
      type: Function
    },
    fileDataIds: {
      type: Array
    },
    dataFilefuns: {
      type: Object
    },
    countParm: {
      type: Object
    },
    formParms: {
      type: Array
    },
    hisData: {
      type: Object
    },
    pageParm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    titleText: {
      type: String,
      default: '页面标题'
    },
    openDialog: {
      type: Boolean
    },
    dataFileTitle: {
      type: String
    },
    dataFileDirPath: {
      type: String
    },
    openDialogDataFile: {
      type: Boolean
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    breadcrumb: {
      type: Boolean,
      default: false
    },
    titleMenus: {
      type: Array
    },
    tableActions: {
      type: Array
    },
    countFun: {
      type: Function
    },
    searchParms: {
      type: Array
    },
    noteText: {
      type: String,
      default: '副标题'
    },
    dataList: {
      type: Array
    },
    columnList: {
      type: Array
    },
    loading: { type: Boolean, default: false },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: Boolean, default: true },
    tableSize: { type: String, default: 'large' },
    toolbox: {
      type: Array, default () {
        return []
      }
    },
    exportFileName: { default: '导出数据' },
    tableKey: { type: String, default: 'main' },
    applyDataLogColumns: { type: Array },
    applicableType: { type: String, default: null }
  },
  data () {
    return {
      showDataApplyLog: false
    }
  },
  computed: {
    baseColumns () {
      let data = []
      if (!this.tableActions || !this.tableActions.length || this.columnList.find(v => v.slot === 'actions')) {
        data = this.columnList
      } else {
        let width = this.tableActionsWidth
        if (!width) {
          width = this.tableActions.length * 20
          this.tableActions.forEach(v => {
            width += v.label.length * 14
          })
          width += 16
        }
        console.log('width', width)
        data = [...this.columnList, {order: 99999, title: '操作', field: 'actions', slot: 'actions', width: width, fixed: this.tableActionsFixed ? 'right' : false, search: false, export: false, configurable: false}]
      }
      data.forEach(v => {
        if (v.dataType === Date && v.filterRange === true) {
          v.width = 260
        } else if (v.dataType === Number && v.filterRange === true) {
          v.width = v.width || 130
        } else if (v.filters && !v.filters.find(v1 => v1.value === '__null')) {
          v.filters.push({
            value: '__null',
            label: '值为空'
          })
          v.width = v.width || 120
        } else {
          v.width = v.width || 120
        }
      })
      return data
    },
    columns () {
      return this.$getTableConfig(this.tableKey, this.baseColumns)
    }
  },
  methods: {
    defaultOptionsFilterMethod (filterValue, columnValue) {
      return filterValue === columnValue
    },
    addFile (fileData) {
      this.$emit('dataFileUpdate', this.fileDataIds.concat(fileData.id))
    },
    delFile (fileData) {
      this.$emit('dataFileUpdate', this.fileDataIds.filter(v => Number(v) !== fileData.id))
    },
    // dataFileUpdate (parm) {
    //   this.$emit('dataFileUpdate', parm)
    // },
    getTableData () {
      return this.$refs.table.sortData
    },
    formSubmit (data, resolve) {
      this.$emit('formSubmit', data, resolve)
    },
    formHandleClose () {
      this.$emit('formHandleClose')
    },
    dataFileHandleClose () {
      this.$emit('dataFileHandleClose')
    },
    countDataChange (parm) {
      this.$emit('countDataChange', parm)
    },
    clickSearch (parm) {
      this.$emit('clickSearch', parm)
    },
    clickTitleMenu (key, parm) {
      if (key === 'applyLog') {
        this.showDataApplyLog = true
      }
      this.$emit('clickTitleMenu', key, parm)
    },
    tableAction (parm) {
      this.$emit('tableAction', parm)
    },
    currentPageChange (parm) {
      this.$emit('currentPageChange', parm)
    },
    checkDataChange (parm) {
      this.$emit('checkDataChange', parm)
    }
  },
  mounted () {
    this.$tableColumnUpdate(this.$refs.table, this.tableKey)
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
  .fm-table-head-cells {
    background-color: #FFF;
  }
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
