import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

import routeData from './router_data'

Vue.use(Router)

const fmRouter = new Router({routes: routeData})

let testNeedAuth = function (router) {
  // 进入的页面是否需要登录
  return !router.path.includes('login')
}

let testAuthPass = function () {
  // 用户是否已经登录
  // 可以加上用户是否有权限访问
  return store.getters.authPass
}

fmRouter.beforeEach((to, from, next) => {
  if (testNeedAuth(to)) {
    if (testAuthPass()) {
      if (store.getters.clientType === 'wap' && to.name === 'app') {
        next({
          name: 'wap.menu'
        })
      } else if (from.name === 'count.count' && to.name === 'app') {
        next({
          name: 'count.home'
        })
      } else {
        next()
      }
    } else {
      if (to.name !== 'home') {
        store.dispatch('setJumpPageParm', to)
      }
      store.dispatch('authInit')
      next(false)
    }
  } else {
    next()
  }
})

export default fmRouter
